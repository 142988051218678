var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline-end-page-component" } },
    [
      _vm.loaded
        ? _c("div", { staticClass: "stageline-end-page-container" }, [
            _c("h2", [_vm._v("\n      Congratulations—you did it!\n    ")]),
            _vm.isSlide
              ? _c("picture", [
                  _c("source", {
                    attrs: {
                      srcset: require("@images/illustrations/stageline/balloon-animation.webp"),
                      type: "image/webp",
                    },
                  }),
                  _c("img", {
                    staticClass: "balloon-animation",
                    attrs: {
                      src: require("@images/illustrations/stageline/balloon-animation.gif"),
                      alt: "Balloon Animation",
                    },
                  }),
                ])
              : _vm._e(),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(
                "\n      You’ve reached the end of our business guide.\n    "
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n      We’re getting ready to launch more business services and resources—check back soon!\n    "
              ),
            ]),
            _vm.isSlide
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row flex-nowrap justify-content-center py-3",
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-preferred",
                        attrs: { "aria-label": "finished button" },
                        on: { click: _vm.finished },
                      },
                      [
                        _c("span", { staticClass: "px-2" }, [
                          _vm._v("\n          Finished!\n        "),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }